import React, { useContext, useState, useEffect } from "react";

//React router dom:
import { useNavigate } from "react-router-dom";

//Context:
import { LoginContext } from "../context";

//Material UI:
import { Container, Box, Grid, IconButton, Typography, Button } from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import VisibilityIcon from "@mui/icons-material/Visibility";

//Styles MUI:
import {
    containerFormLogin, 
    gridContainerLogin,
    titleContainer,
    iconArrowBack,
    typographyTitleOne,
    typographyTitleTwo, 
    linkContainerForgotPassword,
    linkForgotPassword,
} from "../styles-mui/loginStyleMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import FieldComponent from "widgets/field/component";
import ErrorMessageComponent from "widgets/errorMessage/component";
import SignUpLinkComponent from "widgets/signup/component";

const LoginComponent = () => {
    const loginContext = useContext(LoginContext);
    const history = useNavigate();
    const { pressed } = useState(false);

    const { loading } = loginContext || {
        variant: undefined,
        progress: undefined,
    };

    const [shownPassword, setShownPassword] = useState("password");

    const setPasswordFieldType = () => {
        if (shownPassword === "text") {
            setShownPassword("password");
        } else {
            setShownPassword("text");
        }
    };

    useEffect(() => {
        window.onpopstate = () => {
            if (pressed) history(`/login`);
            else history("/");
        };
    }, [pressed]);

    const goToResetPassword = () => {
        history("/forgot-password");
    };

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainerLogin}>   
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={7} sx={titleContainer}>                     
                        <a href="/client">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>
                            Inicia                        </Typography>
                        <Typography sx={typographyTitleTwo}>
                            Sesión
                        </Typography>  
                    </Grid>
                    <Grid item xs={12} md={5} sx={containerFormLogin}>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={loginContext.handleSubmit(
                                loginContext.sendLogin
                            )}
                        >           
                            <Box sx={{ mt: 5, mb: 4 }}>
                                <FieldComponent
                                    fullWidth={true}
                                    fieldContext={loginContext}
                                    fielEndIcon=""
                                    fieldStartIcon=""
                                    fieldClassName="user-phone-number-textfield"
                                    fieldName="direct_number"
                                    fieldLabel="Número celular"
                                    fieldPlaceholder="Número Dalefon a 10 dígitos"
                                    fieldDisabled={false}
                                    fileldDefaultValue=""
                                    fieldRequired={{
                                        required:
                                            "El número telefónico es requerido",
                                    }}
                                    fieldError={
                                        <ErrorMessageComponent
                                            errorParams={loginContext || {}}
                                            errorType={"direct_number"}
                                        />
                                    }
                                />
                            </Box>

                            <Box sx={{ mb: 3 }}>
                                <FieldComponent
                                    fullWidth={true}
                                    fieldType={shownPassword}
                                    fieldContext={loginContext}
                                    fielEndIcon={
                                        <IconButton>
                                            <VisibilityIcon />
                                        </IconButton>
                                    }
                                    fieldStartIcon=""
                                    fieldClassName="user-password-textfield"
                                    fieldName="password"
                                    fieldLabel="Contraseña"
                                    fieldPlaceholder="Contraseña"
                                    fieldDisabled={false}
                                    fileldDefaultValue=""
                                    fieldRequired={{
                                        required: "La contraseña es requerida",
                                    }}
                                    fieldError={
                                        <ErrorMessageComponent
                                            errorParams={loginContext || {}}
                                            errorType={"password"}
                                        />
                                    }
                                    fieldEndAdornmentCallback={setPasswordFieldType}
                                />
                            </Box>

                            <Box mt={3}>
                                <CustomButtonComponent
                                    async={true}
                                    loading={loading}
                                    bgColor={"primary-purple"}
                                    text={"Iniciar sesión"}
                                    height={55}
                                />
                            </Box>

                            <Box sx={linkContainerForgotPassword}>                             
                                <Button sx={linkForgotPassword} onClick={goToResetPassword}>
                                    <span>Olvidé mi contraseña</span>
                                 </Button>
                            </Box>

                            <Box
                                className={"login-register"}
                                sx={{ textAlign: "center" }}
                            >
                                <span
                                    className={
                                        loginContext.loginResponseCls.container ||
                                        ""
                                    }
                                >
                                    <h5
                                        className={
                                            loginContext.loginResponseCls.text || ""
                                        }
                                    >
                                        {loginContext.loginResponse || ""}
                                    </h5>
                                </span>
                            </Box>
 
                            <SignUpLinkComponent /> 
                        </form>
                    </Grid>
                </Grid>            
            </Container>
        </BaseFirstStepsComponent>
    );
};

export default LoginComponent;