import { useEffect, useState } from "react";

//Material UI:
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//React Google Charts:
import { Chart } from "react-google-charts";

//Material UI Styles:
import {
    gridContainerGraphLabel,
    gridItemContainerGraphic,
    typographyGraphLabel,
    typographyItem,
    typographyValueItem,
} from "./styles-mui/graphicCardStylesMUI";

//CSS:
import "../../../../../../../../assets/styles/css/portal/pages/home/sections/active-offers/components/content-card/graphic-card.css";

function GraphicCardComponent({
    dataUnused,
    dataTotals,
    colorsList,
    graphLabel,
}) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const [dimensionsGreaterEqualMD, setdimensionsGreaterEqualMD] =
        useState(null);

    useEffect(() => {
        setdimensionsGreaterEqualMD(matches);
        return () => {};
    }, [matches]);

    const options = {
        legend: "none",
        pieHole: 0.75,
        pieSliceText: "none",
        pieStartAngle: -125,
        tooltip: { trigger: "none" },
        slices: {
            0: { color: colorsList[0 % colorsList.length] },
            1: { color: "#F1F1F1" },
            2: { color: "transparent" },
        },
        width: "100%",
        backgroundColor: "transparent",
        height: dimensionsGreaterEqualMD ? 400 : 300,
        chartArea: { top: dimensionsGreaterEqualMD ? "auto" : 0 },
    };
    return (
        <Grid
            item
            md={6}
            xs={12}
            className="container-graphic-card"
            sx={gridItemContainerGraphic}
        >
            <Grid container className="chart-container" mb={2.2}>
                <Grid
                    item
                    xs={12}
                    className="chart"
                    sx={{ position: "relative"}}
                >
                    <Chart
                        chartType="PieChart"
                        data={[
                            ["", "Percentage"],
                            ["", ((dataUnused * 100) / dataTotals) * 0.7],
                            ["", 70 - ((dataUnused * 100) / dataTotals) * 0.7],
                            ["", 30],
                        ]}
                        options={options}
                        width={"100%"}
                        height={dimensionsGreaterEqualMD ? "400px" : "218px"}  
                    />

                    <Grid container sx={gridContainerGraphLabel}>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography variant="h4" sx={typographyGraphLabel}>
                                {Math.round(dataUnused).toLocaleString("en")}{" "}
                                {graphLabel}
                            </Typography>
                            <Typography sx={{ fontSize: "15px", m: 0 }}>
                                Disponibles
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className="data"
                    sx={{ mt: { xs: "-50px", md: "-110px" }, zIndex: 99 }}
                >
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Typography
                            sx={typographyValueItem}
                            className="colored"
                        >
                            {Math.round(dataTotals).toLocaleString("en")}{" "}
                            {graphLabel}
                        </Typography>
                        <Typography variant="p" sx={typographyItem}>
                            Totales
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Typography
                            sx={typographyValueItem}
                            className="colored"
                        >
                            {Math.round(dataTotals - dataUnused).toLocaleString(
                                "en"
                            )}{" "}
                            {graphLabel}
                        </Typography>
                        <Typography variant="p" sx={typographyItem}>
                            Consumidos
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default GraphicCardComponent;
