import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const NewPin = (props) => {
    const {
        phone,

        validationPinEpired,
        pinCodeAccount,
        pin,
    } = props;

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const controllerNameStorage = localStorage.getItem("controllerNameStorage");

    // useEffect(() => {
    //     if (validationPinEpired !== 200 && pinCodeAccount !== undefined) {
    //         navigate("/forgot-password/media-selection-pin", {
    //             state: {
    //                 textFieldValue: phone,
    //                 controllerNameStorage: controllerNameStorage,
    //             },
    //         });
    //     }
    // }, [pinCodeAccount, validationPinEpired, navigate, phone]);

    const onSubmit = () => {};

    return (
        <Container>
            <Grid container justifyContent="flex-end">
                <LoadingButton
                    color="primary" 
                    onClick={onSubmit}
                    loading={loading}
                    loadingPosition="end"
                >
                    Reenviar
                </LoadingButton>
            </Grid>
            <Grid container justifyContent="flex-start">
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        </Container>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewPin);
