import React from "react";

//Images:
import signupImage from "assets/images/login/signin-image.png";

//Material UI:
import { Box, Grid } from "@mui/material";

//Components:
import SignUpMainFormComponent from "./components/SignUpMainFormComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

function SignUpMainPageComponent() {
    return (
        <BaseFirstStepsComponent>
            <Grid container>
                <SignUpMainFormComponent />
            </Grid>
        </BaseFirstStepsComponent>
    );
}

export default SignUpMainPageComponent;
