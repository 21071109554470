import React from "react";

//Material UI:
import {
    Container,
    Grid,
    Box,
    Typography, 
    TextField,
} from "@mui/material";

//CSS:
import "assets/styles/css/gift/gift.css";

//Assets:
import imgLogo from "assets/images/logo.png";
import imgRibbon from "assets/images/gift/ribbon.png";
import imgNoShareWifi from "assets/images/utils/components/cards/no-hotspot.svg";
import imgSocialMedia from "assets/images/utils/components/cards/social-media.svg";
import imgSocialMediaResponsive from "assets/images/gift/social-media-responsive.svg";

//React Icons:
import { FaCheck } from "react-icons/fa6";
 
//Styles MUI:
import { 
    gridContainer,     
    gridResponsive,
    logoContainer,
    typographyTitleOne,
    typographyTitleTwo, 
    typographyTitleThree,
    typographyGB,
    typographyGBSpan,
    typographyPlusGB,
    typographyLabel,
    TypographyValidity,
    boxContainerImg,    
    boxContainerResponsiveImg,
    boxContainerNoShareAndShareWifi,
    typographyNoShareAndShareWifi,
    boxContainerMinAndSMS,
    titleContainer, 
    buttonComponent,
} from "../styles-mui/giftStyleMUI";

//Components: 
import CustomButtonComponent from "utils/templates/CustomButtonComponent"; 

function GiftMainFormComponent(){  
    return (
        <Container maxWidth="lg"  className="gift-container">
            <Grid container sx={gridContainer}>
                <Grid item xs={12} md={7} sx={titleContainer}>                      
                    <Box sx={logoContainer}>
                        <img 
                            src={imgLogo}
                            className="logo-title"
                        />
                    </Box>  
                    <Typography sx={typographyTitleOne}>
                        La Telefonía
                    </Typography>     
                    <Typography sx={typographyTitleTwo}>
                        Morada
                    </Typography>                     
                    <Typography sx={typographyTitleThree}>
                        Te regala
                    </Typography>  
                </Grid>
                <Grid item xs={12} md={5}> 
                    <Grid container display={"flex"} justifyContent={"center"} className="card-container">   
                        <Grid item xs={12}>
                            <img 
                                src={imgRibbon}
                                className="ribbon-image"
                        />
                        </Grid>
                        <Grid item xs={12}>
                            <img
                                src={imgLogo}
                                alt="logo"
                                className="logo-image"
                            /> 
                            {/* Sección de GB */}
                            <Typography sx={typographyGB}>
                                10GB
                                <Typography variant="span" sx={typographyGBSpan}>
                                    *
                                </Typography>
                            </Typography>
                            
                            {/* Sección más GB para redes sociales */}
                                <Typography sx={typographyPlusGB}>
                                    + Redes Sociales
                                </Typography>
                        </Grid>
                        <Grid item xs={12} sx={gridResponsive}>
                                {/* Redes sociales */}
                                <Box sx={boxContainerImg}>
                                    <img
                                        src={imgSocialMedia}
                                        alt="Redes sociales"
                                        className="img-social-media"
                                    />
                                </Box>      
                                <Box sx={boxContainerResponsiveImg}>
                                    <img
                                        src={imgSocialMediaResponsive}
                                        alt="Redes sociales"
                                        className="img-social-media-responsive"
                                    />
                                </Box>                         
                        </Grid>
                        <Grid item xs={10} md={7}>
                            {/* Vigencia */ }
                                <Typography sx={TypographyValidity}>
                                    7 días de vigencia
                                </Typography>
                            {/* No Share Wifi */}
                                <Box sx={boxContainerNoShareAndShareWifi}>
                                    <img
                                        src={imgNoShareWifi}
                                        width={15}
                                        height={15}
                                        className="circular-icon"
                                    />
                                    <Typography sx={typographyNoShareAndShareWifi}>
                                            No comparte datos
                                    </Typography>
                                </Box>
                            {/* Minutos y SMS */}
                                <Box sx={boxContainerMinAndSMS}>
                                    <FaCheck className="circular-icon" />
                                    <Typography>Minutos y SMS</Typography>
                                </Box>       

                            {/* IMEI */}                 
                            <Box>
                                <TextField  
                                    id="outlined"
                                    label="Ingresa el IMEI de tu celular" 
                                    fullWidth={true}
                                    placeholder="Ej. 359849006180357 "
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            color: "#e9e9e9", 
                                            "&.Mui-focused": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#e9e9e9", 
                                                },
                                            },
                                            "&:hover:not(.Mui-focused)": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#fc2bdc",
                                                },
                                            },
                                        },
                                        "& .MuiInputLabel-outlined": {
                                            color: "#e9e9e9", 
                                            "&.Mui-focused": {
                                                color: "#e9e9e9", 
                                            },
                                        },
                                    }}

                                />                            
                            </Box>     
                            {/* Button */}      
                            <Box sx={buttonComponent}>
                                <CustomButtonComponent
                                    async={true} 
                                    bgColor={"rose"}
                                    text={"Verificar compatibilidad"}
                                    height={55}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>                           
                            <Box sx={{ mt: 3 }}>     
                                <Typography sx={typographyLabel}>
                                    *Al aceptar este regalo, estás llevándote un Dale 69
                                </Typography>  
                            </Box> 
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </Container>
    );
} 

export default (GiftMainFormComponent);