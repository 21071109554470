import React, { useContext, useEffect, useState } from "react";

//Router:
import { useNavigate, useLocation } from "react-router-dom";

//App context:
import { AppContext } from "_models/context/app";

//Redux:
import { connect } from "react-redux";

//Material UI:
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

//Hooks:
import { useForm } from "react-hook-form";

//Styles MUI:
import { 
    gridContainer,
    titleContainer,
    formContainer, 
    iconArrowBack,
    typographyTitleOne,
    typographyTitleTwo,  
    retryContainer,
    buttonContainer
} from "../styles-mui/securityCodeStyleMUI";

//Components: 
import CustomButtonComponent from "utils/templates/CustomButtonComponent"; 
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent"; 

import { VerificationPin } from "react-verification-pin";
import { validatePinAction } from "_models/redux/validate-pin/action";

const SecurityCodeSignUpPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { contactPhone, gettingType, data } = location.state || {};

    const { validatePin } = props;
    const [pin, setPin] = useState();
    const appContext = useContext(AppContext);
    const { setpinCodeNumber } = appContext;

    const [status, setStatus] = useState("process");
    const [attemptedValidation, setAttemptedValidation] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (validatePin?.status === 200) {
            setStatus("success");
            navigate(`/reset-password/set-password`, {
                state: { contactPhone, pin },
            });
        } else if (validatePin?.status > 200 && attemptedValidation) {
            setStatus("error");
        }
    }, [validatePin, navigate, contactPhone, pin, attemptedValidation]);

    const handleOnFinish = (code) => {
        setStatus("wait");
        setAttemptedValidation(true);
        setPin(code);

        const post = {
            code: parseInt(code),
            directNumber: contactPhone,
        };
        props.validatePinAction(post);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        setLoading(true);
    };

    const handleGoBack = () => {
        navigate("/forgot-password/media-selection-pin", {
            state: { contactPhone, gettingType, data },
        });
    };

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainer}>
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={7} sx={titleContainer}>                     
                        <a href="/forgot-password">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>
                            Código
                        </Typography>
                        <Typography sx={typographyTitleTwo}>
                            De verificación
                        </Typography>  
                    </Grid>

                    <Grid item xs={12} md={5} sx={formContainer}>                    
                        <form onSubmit={handleSubmit(onSubmit)}> 
                                                   
                            <VerificationPin
                                {...register("pinCode", {
                                    required: "Pin es requerido",
                                })}
                                fullWidth 
                                type="number" 
                                subTitle="Ingresa el código de verificación que hemos enviado a tu número celular."
                                inputsNumber={5}
                                name="pinCode"
                                status={status} 
                                onFinish={handleOnFinish}
                                onChange={(value) => {
                                    setpinCodeNumber(value);
                                    if (value.length === 0) {
                                        setAttemptedValidation(false);
                                        setStatus("wait");
                                    }
                                }}                 
                                                
                            />                            
                            <Grid container>
                                <span>
                                    {errors.pinCode && (
                                        <p className="required-incidence">
                                            {errors.pinCode.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <Grid container display={"flex"} justifyContent={"end"}>                                  
                                <span>
                                    {status === "error" &&
                                        validatePin?.status >
                                            200 && (
                                            <div>
                                                <Button
                                                    onClick={
                                                        handleGoBack
                                                    }   
                                                    sx={retryContainer}                                                 
                                                >
                                                    Reenviar
                                                </Button>
                                            </div>
                                        )}
                                </span> 
                            </Grid>
                            
                            <Box sx={buttonContainer}>
                                <CustomButtonComponent
                                    async={true}
                                    onClick={handleOnFinish}
                                    bgColor={"primary-purple"}
                                    text={"Continuar"}
                                    height={55}
                                    loading={loading}
                                />
                            </Box>
                        </form>
                    </Grid>
                    </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

const mapStateToProps = (state) => ({
    validatePin: state?.validatePin,
});

const mapDispatchToProps = {
    validatePinAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityCodeSignUpPage);
