import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/es";

// Material UI:
import { Box, Grid, Typography } from "@mui/material";

// Components:
import ProgressBar from "modules/portal/components/progress-bar";

// Material UI Styles:
import {
    gridItemConsumed,
    gridItemValidity,
    typographyDateLabel,
    serviceItemFooter,
} from "./styles-mui/valuesContentStylesMUI";
import { typographyBeforePrice } from "utils/components/cards/card-no-plans/styles-mui/cardNoPlansStylesMUI";

moment.locale("es");

function ValuesContentCardComponent(myProps) {
    const { item, index, graphLabel, dataExpireDate, colorsList } =
        myProps || {};

    const [now, setNow] = useState(moment());

    useEffect(() => {
        const timer = setInterval(() => {
            setNow(moment());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    if (item !== undefined && index !== undefined) {
        const { total, unused } = item || {};
        const consumed = total - unused;

        const expireDate = dataExpireDate
            ? moment(dataExpireDate[index], "YYYYMMDDhmmss").subtract(
                  1,
                  "seconds"
              )
            : null;

        return (
            <>
                <Grid container mb={2}>
                    <Grid item xs={12} md={6} sx={gridItemConsumed}>
                        <Typography sx={typographyDateLabel}>
                            {expireDate ? (
                                <>
                                    Vence el{" "}
                                    <Moment
                                        format="DD [de] MMM [de] YYYY [a las] HH:mm:ss"
                                        locale="es"
                                    >
                                        {expireDate}
                                    </Moment>
                                </>
                            ) : (
                                ""
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={gridItemValidity}>
                        <Typography sx={typographyDateLabel}>
                            Expira{" "}
                            <Moment to={expireDate} unit="days">
                                {now}
                            </Moment>
                        </Typography>
                    </Grid>
                </Grid>
                <ProgressBar
                    key={"color-" + index}
                    bgcolor={colorsList[index % colorsList.length]}
                    completed={Math.round(100 - (consumed * 100) / total)}
                />
                <Box
                    sx={serviceItemFooter}
                    mb={2}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                         
                    }}
                > 
                    <span>
                        Te quedan {Math.round(unused).toLocaleString("en")}{" "}
                        {graphLabel} de {Math.round(total).toLocaleString("en")}{" "}
                        {graphLabel}
                    </span>
                    <span>
                        Consumidos: {parseFloat(consumed).toFixed(2)}{" "}
                        {graphLabel}
                    </span>
                </Box>
            </>
        );
    } else {
        return null;
    }
}

export default ValuesContentCardComponent;
