export const gridItemBase = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    maxheight: "100vh",
    width: "100%",
    textAlign: "center",
    py: { xs: 0, md: 10 },
    px: 0,
};

export const gridContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const gridResponsive = {
    mt: { xs: 65, sm: 132, md: 0 },
};

export const logoContainer = {
    mb: 6,
    display: { xs: "none", md: "block" },
};

export const titleContainer = {
    display: { xs: "none", md: "flex" },
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const typographyTitleOne = {
    fontSize: { xs: 36, md: 62 },
    color: "white",
    lineHeight: 1.2,
    fontFamily: "Poppins-Regular",
    fontWeight: 500,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 40, md: 95 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: "start",
};

export const typographyTitleThree = {
    fontSize: { xs: 40, md: 79 },
    color: "white",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: "start",
};

export const typographyGB = {
    fontSize: 90,
    fontWeight: "bold",
    lineHeight: 1.2,
    mt: 2,
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const typographyGBSpan = {
    fontSize: 20,
    top: 20,
    position: "relative",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const typographyPlusGB = {
    fontSize: 25,
    lineHeight: 1.2,
    fontWeight: "500",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const boxContainerImg = {
    textAlign: "right",
    p: 0,
    mr: { xs: -2.8, md: -3 },
    my: -5.5,
    display: { xs: "none", md: "block" },
};

export const boxContainerResponsiveImg = {
    my: 3,
    display: { xs: "flex", md: "none" },
};

export const TypographyValidity = {
    fontSize: 17,
    fontWeight: "500",
    mb: 3,
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
};

export const boxContainerNoShareAndShareWifi = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    my: 1.5,
};

export const typographyNoShareAndShareWifi = {
    fontSize: 14,
    color: "white",
};

export const boxContainerMinAndSMS = {
    display: "flex",
    justifyContent: "center",
    gap: 1,
    alignItems: "center",
    mb: 4,
    fontSize: 14,
};

export const buttonComponent = {
    mt: 4,
    mb: 2,
};

export const typographyLabel = {
    fontSize: 12,
};
