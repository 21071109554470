export const gridContainer = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

export const titleContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const typographyTitleOne = {
    fontSize: { xs: 40, md: 67 },
    color: "white",
    lineHeight: { xs: 1.5, md: 1.2 },
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 45, md: 72 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: "start",
};

export const iconArrowBack = {
    fontSize: 32,
    color: "white",
    mb: { xs: 1, md: 1 },
    mt: 0,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};

export const formContainer = {
    mt: { xs: 4.5, md: 0 },
    mb: { xs: 3, md: 0 },
    backgroundColor: "white",
    borderRadius: 7,
    px: { xs: 3, md: 8 },
    pt: { xs: 1, md: 4 },
    pb: { xs: 0, md: 1 },
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
};

export const typographyDescription = {
    mt: { xs: 2, md: 0 },
    fontSize: 16,
    mb: 3,
    textAlign: "start",
    lineHeight: { xs: 1.7, md: 1.6 },
};

export const buttonContainer = {
    mt: { xs: 2.5, md: 3 },
    mb: { xs: 2, md: 3 },
};
