import React from "react";

//Material UI:
import { Box } from "@mui/material";

//Styles MUI:
import {
    gridItemBase,
} from "./styles-mui/giftStyleMUI";

//Components:
import GiftMainFormComponent from "./components/GiftMainFormComponent"; 

function GiftMainPageComponent() {
    return (  
        <Box sx={gridItemBase}>
                <GiftMainFormComponent />
        </Box>
            
    );
}

export default GiftMainPageComponent;
