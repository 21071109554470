export const leyendText = {
    fontSize: 14,
    color: "#5f5f5f",
};

export const buttonText = {
    color: "#5f5f5f",
    fontFamily: "Poppins-Regular",
    fontWeight: 500,
    fontSize: 14,
    textTransform: "none",
    pl: 0.5,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};
