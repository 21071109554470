import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Material UI:
import { Container, Grid, Box, Typography } from "@mui/material";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Images:
import errorImage from "assets/images/reset-password/error.png";

//Styles MUI:
import {
    gridContainer,
    imageContainer,
    typographyTitle,
    typographyDescription,
    buttonContainer,
} from "../styles-mui/responseStyleMUI";

const ErrorSignUpPage = (props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorNext, setErrorNext] = useState("");
    const [errorLast, setErrorLast] = useState("");

    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToSignUp = () => {
        history("/sign-up");
    };

    useEffect(() => {
        const { from } = props || { from: undefined };
        if (from == "signup-expired") {
            setErrorMessage("La liga expiró");
            setErrorNext("");
            setErrorLast("Ya puedes cerrar esta ventana");
        } else {
            setErrorMessage("No se pudo procesar la solicitud");
            setErrorNext("Inténtalo nuevamente");
            setErrorLast("");
        }
        return () => {
            //action del clean
        };
    }, []);
    return (
        <BaseFirstStepsComponent>
        <Container maxWidth={"lg"}>
            <Grid container sx={gridContainer}>
                <Grid item xs={12} md={4}>
                    <Box sx={imageContainer}>
                        <img src={errorImage} width={60} />
                    </Box>
                    <Box>
                        <Typography sx={typographyTitle}>
                            Algo salió mal
                        </Typography>
                        <Typography sx={typographyDescription}>
                            La cuenta no fue creada, pero no te
                            preocupes, vuelve a intentarlo.
                        </Typography>
                    </Box>
                    <Box sx={buttonContainer}>
                        <CustomButtonComponent
                            async={true}
                            onClick={goToSignUp}
                            bgColor={"transparent"}
                            text={"Reintentar"}
                            height={55}
                        />                        
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </BaseFirstStepsComponent>
    );
};

export default ErrorSignUpPage;
