export const boxContainerHistoryCard = {
    backgroundColor: "#ffffff",
    width: { xs: "90%", md: "40%", lg: "50%" },
    margin: "0 auto !important",
    borderRadius: "10px",
    position: "relative",
    border: "1px solid #d2cdd9",
};

export const gridContainerPurchaseDetails = {
    color: "white",
    padding: "10px 20px",
    borderRadius: "10px 10px 0 0",
    py: 2,
};

export const gridItemProductName = {
    lineHeight: "12px",
};

export const typographyTitleProductName = {
    fontSize: 22,
    mb: 2,
};

export const gridItemValidity = {
    textAlign: "right",
    fontSize: "14px",
};

export const gridContainerHistoryDetail = {
    padding: "10px 20px",
    display: "flex",
    flexDirection: "row",
    /* flexWrap: "nowrap", */
    alignContent: "center",
    alignItems: "center",
};

export const spanHistoryDetail = {
    fontWeight: 600,
    color: "#4f4c4c",
};

export const gridItemMeansOfPayment = {
    display: "flex",
    justifyContent: { xs: "flex-start", md: "flex-end" },
    fontSize: 18,
    mb: 1,
};

export const boxMessageInvoiceGenerated = {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
    color: "#533383",
    fontSize: 20,
};
