export const gridItemContainerGraphic = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const gridContainerGraphLabel = {
    justifyContent: "center",
    position: "absolute",
    bottom: { xs: "100px", md: "180px" },
    left: "auto",
    right: "auto",
};

export const typographyGraphLabel = {
    m: 0,
    fontSize: { xs: "20px", md: "24px" },
    color: "#2d263a",
};

export const typographyValueItem = {
    fontSize: "17px",
    color: "#2d263a",
    fontWeight: 900,
};

export const typographyItem = { fontSize: "15px", fontWeight: 100 };
