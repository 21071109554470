import { useEffect, useState } from "react";

//Material Ui:
import { CircularProgress, Container, Grid, Tab, Tabs, Typography } from "@mui/material";

//Images: 

//Redux:
import { connect } from "react-redux";
import { productItemAction } from "_models/redux/product-item/action";

//Hooks:
import { useProductsCardsHook } from "utils/components/flows-products/useProductsCardsHook";

//Components:
import ContentPrepaidsProductsComponents from "utils/components/flows-products/components/ContentPrepaidsProductsComponents";
import ContentNoPlansProductsComponents from "utils/components/flows-products/components/ContentNoPlansProductsComponents";

const ProductsView = (props) => {
    const { productItem } = props;
    const { productItemgObj } = productItem || "undefined";
    const { products } = productItemgObj || "undefined";

    const [tabIndex, setTabIndex] = useState(0);

    const [loaderCls, setLoaderCls] = useState("");

    /*** Arreglos de subcategorías de los productos ***/

    const [classicSorted, setClassicSorted] = useState([]);
    const [plusSorted, setPlusSorted] = useState([]);

    const [shareSorted, setShareSorted] = useState([]);
    const [notShareSorted, setNotShareSorted] = useState([]);

    const params = {
        products,
        classicSorted,
        setClassicSorted,
        plusSorted,
        setPlusSorted,
        shareSorted,
        setShareSorted,
        notShareSorted,
        setNotShareSorted,
    };

    useProductsCardsHook(params);

    useEffect(() => {
        if (Array.isArray(products)) {
            setLoaderCls("elementHidden");
        }
    }, [products]);

    useEffect(() => {
        props.productItemAction();
    }, []);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const tabsStyle = {
        "& button": {
            borderRadius: 4,
            color: "white",
            height: "170px",
            width: "210px",
            fontSize: "24px",
            fontFamily: "poppins-Regular",
            marginRight: 4,
            background: "#5146737a",
            textTransform: "none", 
        },
        "& button:hover": {
            background: "#6b5e937a",
            borderRadius: 4,
            color: "#f9f9f9 !important",
        },
        "& button:focus": {
            borderRadius: 4,
            color: "#5d079a",
        },
        "& button:active": {
            backgroundColor: "#6b5e937a",
            borderRadius: 4,
            color: "#ffffff",
        },
        "& button.Mui-selected": {
            background: "linear-gradient( 90deg, #b427ff 20%, #8677f4 100% )",
            borderRadius: 4,  
            color: "#FFFFFF !important",
            fontFamily: "poppins-SemiBold",
        },
    };

    return (
        <>
            <Container maxWidth={false} disableGutters >
                <Grid container className="header">
                    <Grid item xs={12} className="clasedeprueba">
                        <Grid container  mt={{xs: 1, md: 5}} mb={{xs: 0, md: 5}}>
                            <Grid item xs={12} md={6} className="products" mb={3}>
                                <Typography className="title">Dale</Typography>
                                <Typography className="subtitle">más megas</Typography>
                                <Typography className="hypertitle">a tu fon</Typography>                                
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6} 
                                px={{xs:3.5, md: 0}}
                                py={{xs:3, md: 0}}
                                display={{md:"flex"}}
                                justifyContent={{xs: "center", md:"right"}}

                            >
                               <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    TabIndicatorProps={{
                                        sx: { display: "none" },
                                    }}
                                    sx={tabsStyle}
                                    className="products-tab"
                                >
                                    <Tab
                                        label="Recargas"
                                        className="tab main"
                                    />
                                    <Tab
                                        label="No Planes"
                                        className="tab main"
                                    />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            item
                            className={"center_circular_progress " + loaderCls}
                        >
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {tabIndex === 0 && (
                <ContentPrepaidsProductsComponents
                    shareSorted={shareSorted}
                    notShareSorted={notShareSorted}
                    tabIndex={tabIndex}
                    flow={"prepaid"}
                />
            )}

            {tabIndex === 1 && (
                <ContentNoPlansProductsComponents
                    classicSorted={classicSorted}
                    plusSorted={plusSorted}
                    products={products}
                    flow={"prepaid"}
                />
            )}
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    productItem: state.productItem,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    productItemAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ProductsView);
