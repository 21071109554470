import React from "react";

//Material UI:
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Components:
import CollapseCardsComponent from "./CollapseCardsComponent";
import ValuesContentCardComponent from "./content-card/ValuesContentCardComponent";
import GraphicCardComponent from "./content-card/GraphicCardComponent";

//Styles MUI:
import {
    cardContainer,
    gridItemContainerPackages,
    serviceItemContainer,
    serviceItemTitle,
} from "./styles-mui/contentCard";

//Css:
import "../../../../../../../assets/styles/css/portal/pages/home/sections/active-offers/components/content-card.css";

/** Style Colors **/
const colorsList = ["#B427FF", "#8E68F6 ", "#C854F6"];

function ContentCardComponent({
    dataTotals,
    dataUnused,
    graphLabel,
    dataExpireDate,
    serviceStateArray,
    responseTotalsStateArray,
    expandedArray,
    handleExpandClick,
}) {
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    return (
        <Grid container className="content-card-container" sx={cardContainer}>
            <GraphicCardComponent
                dataUnused={dataUnused}
                dataTotals={dataTotals}
                colorsList={colorsList}
                graphLabel={graphLabel}
            />

            <Grid
                item
                md={6}
                xs={12}
                className="card-item"
                sx={gridItemContainerPackages}
            >
                <Box>
                    
                    <Grid
                        item
                        xs={12}
                        overflow="auto"
                        sx={{ pr: { xs: 0, md: "30px" } }}
                        className="bag-item-container"
                    >
                        {responseTotalsStateArray.map((item, index) => {
                            const { total } = item || {};

                            if (total > 0) {
                                return (
                                    <Box
                                        className="service-item-wrapper"
                                        key={index}
                                        sx={serviceItemContainer}
                                    >
                                        <Box
                                            className="service-item-title"
                                            sx={serviceItemTitle}
                                        >
                                            <span> {item?.name} </span>
                                            <ExpandMore
                                                expand={expandedArray[index]}
                                                onClick={() =>
                                                    handleExpandClick(index)
                                                }
                                                aria-expanded={false}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon className="expand-icon" />
                                            </ExpandMore>
                                        </Box>

                                        <Box>
                                            <Grid container>
                                                <ValuesContentCardComponent
                                                    item={item}
                                                    index={index}
                                                    dataExpireDate={
                                                        dataExpireDate
                                                    }
                                                    graphLabel={graphLabel}
                                                    colorsList={colorsList}
                                                />

                                                <CollapseCardsComponent
                                                    expandedArray={
                                                        expandedArray
                                                    }
                                                    index={index}
                                                    serviceStateArray={
                                                        serviceStateArray
                                                    }
                                                    graphLabel={graphLabel}
                                                />
                                            </Grid>
                                        </Box>
                                    </Box>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export default ContentCardComponent;
