import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "_models/context/app";

//Redux:
import { connect } from "react-redux";

//Hooks:
import { useForm } from "react-hook-form";

//Material UI:
import { Container, Grid, Box, Typography } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import { VerificationPin } from "react-verification-pin";
import NewPin from "./NewPin";
import { validatePinAction } from "../../../_models/redux/validate-pin/action";

//Styles MUI:
import {
    gridContainer,
    formContainer,
    typographyTitleOne,
    typographyTitleTwo,
    titleContainer,
    iconArrowBack,
    buttonComponent,
} from "../styles-mui/signUpSecurityCodeStyleMUI";

const SecurityCodeSignUpPage = (props) => {
    let navigate = useNavigate();
    const { validatePin } = props;
    const { validatePinObj } = validatePin || "undefined";
    const { response, message } = validatePinObj || "undefined";
    const { data } = response || "undefined";
    const { detail } = data || "undefined";
    const appContext = useContext(AppContext);
    const { pinCodeNumber, directNumber } = appContext;
    const { user_phone_number } = directNumber || "undefined";
    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState("process");
    const [pinCode, createPinCode] = useState("");

    if (message === "success") {
        let pathSuccess = `/sign-up/set-password`;
        navigate(pathSuccess);
    }

    const handleOnFinish = (code = pinCodeNumber) => {
        setStatus("wait");
        const post = {
            code,
            // directNumber,
            // user_phone_number,
        };
        if (user_phone_number != "undefined") {
            post.directNumber = directNumber;
        } else {
            post.user_phone_number = user_phone_number;
        }

        // setIsLoading(true);

        // simulating a search on your api
        if (code == pinCodeNumber) {
            setTimeout(() => {
                setStatus("success");
            }, 3000);
        } else {
            setTimeout(() => {
                setStatus("error");
            }, 3000);
        }
        props.validatePinAction(post);
    };

    const {
        register,
        handleSubmit,
        value,
        getValues,
        formState: { errors },
    } = useForm();

    const registerOptions = {
        pinCode: { required: "El pin es requerido" },
    };

    const onSubmit = (e) => {
        //   e.preventDefault();
        const post = {
            pinCode: pinCode,
        };
        // setIsLoading(true);
        // props.validatePinAction(post);
        // props.postIncidence(post);
    };

    const muestra = (e) => {};

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainer}>
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={7} sx={titleContainer}>
                        <a href="/sign-up">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>Código</Typography>
                        <Typography sx={typographyTitleTwo}>
                            De verificación
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={5} sx={formContainer}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box>
                                <VerificationPin
                                    {...register(
                                        "pinCode",
                                        registerOptions.pinCode
                                    )}
                                    fullWidth
                                    type="number"
                                    inputsNumber={5}
                                    name="pinCode"
                                    status={status}
                                    value={pinCode}
                                    subTitle="Ingresa el
                                                código que hemos enviado a tu número
                                                celular."
                                    onChange={muestra}
                                    onFinish={handleOnFinish}
                                />
                                <Grid item xs={12}>
                                    <span>
                                        {errors?.pinCode && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.pinCode.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                                <Grid container className="messages">
                                    <Grid item xs={12} md={8}>
                                        <span>
                                            <p className="required-incidence">
                                                {detail}
                                            </p>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <span>
                                            {status === "error" ? (
                                                <p className="required-incidence">
                                                    <NewPin
                                                        phone={directNumber}
                                                    />
                                                </p>
                                            ) : null}
                                        </span>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={buttonComponent}>
                                <CustomButtonComponent
                                    async={true}
                                    loading={loading}
                                    bgColor={"primary-purple"}
                                    text={"Continuar"}
                                    height={55}
                                />
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

const mapStateToProps = (state) => ({
    validatePin: state.validatePin,
});
const mapDispatchToProps = {
    validatePinAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityCodeSignUpPage);