export const containerHomeHeader = {
    p: { xs: "0px 20px 0", md: "70px 100px 0" },
    textAlign: { xs: "center", md: "left" },
    color: "white",
};

export const gridContainerHomeHeader = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pb: 5,
};

export const typographyTitle = {
    fontSize: { xs: 36, md: 52 },
    fontWeight: 700,
    mb: 3,
    mt: { xs: 2, md: 1 },
};

export const typographySubtitle = {
    fontSize: { xs: 23, md: 25 },
    lineHeight: 1.5,
    mb: { xs: 4, md: 6 },
    fontWeight: 400,
    "& span": {
        fontWeight: 700,
        fontSize: { xs: 25, md: 28 },
    },
};

export const spanPackagesActive = {
    color: "#a361ff",
    fontWeight: 900,
};

export const gridItemContainerImage = {
    display: { xs: "none", lg: "flex" },
    alignContent: "center",
    justifyContent: "center",
};
