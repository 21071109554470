export const gridItemCollapseCards = {
    mb: 2.5,
    pt: 2.5,
    borderTop: "1px solid #3333",
};

export const typographyPercentage = {
    margin: "0 0 15px",
    fontWeight: 500,
    fontSize: 16,
    color: "#2d263a",
};

export const boxContainerServiceItem = {
    width: "100%",
    fontWeight: 600,
    fontWeight: 500,
    color: "#2d263a",
    fontSize: 14,
};
