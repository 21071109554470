import React from "react";

//Material UI:
import { Grid, Container, Box, Typography, TextField } from "@mui/material";  

//Icons UI:
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

//Styles MUI:
import { 
    gridContainer,
    titleContainer,
    formContainer, 
    iconArrowBack,
    typographyTitleOne,
    typographyTitleTwo,
    typographyDescription,
    buttonContainer
} from "../styles-mui/addPhoneStyleMUI";

//CSS:
import "../../../assets/styles/css/forgot-password/components/add-phone.css";

//Components: 
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent"; 
import SignUpLinkComponent from "widgets/signup/component"; 
import { useForgotPasswordController } from "utils/components/Common/hook/useForgotPasswordController";


const ForgotPasswordComponent = (props) => {
    const {
        errorMessage,
        handleTextFieldChange,
        handleContinueClick,
        textFieldValue,
    } = useForgotPasswordController();

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainer}>
                <Grid container display={"flex"} alignItems={"center"}> 
                    <Grid item xs={12} md={7} sx={titleContainer}>                     
                        <a href="/login">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>
                            Olvidé
                        </Typography>
                        <Typography sx={typographyTitleTwo}>
                            mi contraseña
                        </Typography>  
                    </Grid>

                    <Grid item xs={12} md={5} sx={formContainer}>
                        <Typography sx={typographyDescription}>
                            Enviaremos un <span>código de verificación</span> a tu
                            número celular para restablecerla.
                        </Typography>

                        <Box>
                            <TextField
                                label="Número Dalefon a 10 dígitos"
                                type="text"
                                defaultValue=""
                                onChange={handleTextFieldChange}
                                value={textFieldValue}
                                size="medium"                                
                                sx={{
                                    width: "100%", 
                                    margin: "2px 0 3px 0", 
                                    "& .MuiOutlinedInput-root": {                                                 
                                        "&:hover fieldset": {
                                            borderColor: "#612cab",
                                        },
                                    }
                                }}
                            />
                            {errorMessage && (
                                <p style={{ color: "red" }}>{errorMessage}</p>
                            )}
                        </Box>

                        <Box sx={buttonContainer}>
                            <CustomButtonComponent
                                async={true}
                                onClick={handleContinueClick}
                                bgColor={"primary-purple"}
                                text={"Enviar"}
                                height={55}
                            />
                        </Box>

                        <SignUpLinkComponent />
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

export default ForgotPasswordComponent;
