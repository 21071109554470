
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "_models/context/app";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//Redux:
import { connect } from "react-redux";

//Hooks:
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//Material UI:
import { Container, Grid, Box, TextField, Typography } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//Styles MUI:
import {
    gridContainer,
    formContainer,
    typographyTitleOne,
    typographyTitleTwo,
    titleContainer,
    iconArrowBack,
    buttonComponent,
} from "../styles-mui/signUpSetPasswordStyleMUI";

//Components:
import { setPasswordAction } from "../../../_models/redux/set-password/action";
import PasswordStrengthBar from "react-password-strength-bar"; 
import LoadingButton from "@mui/lab/LoadingButton";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

/*** Paso 1: Importas componente genérico (helper) ***/
import FormRecaptchaComponent from "utils/helpers/recaptcha/form-recaptcha-component";

const SetPasswordSignUpPage = (props) => {
    let navigate = useNavigate();
    const { setPassword } = props;
    const { newPasswordObj } = setPassword || "undefined";
    const { response, message } = newPasswordObj;
    const { data } = response || "undefined";
    const { detail } = data || "undefined";
    const appContext = useContext(AppContext);
    const { pinCodeNumber, directNumber } = appContext;
    const [confirmPwd, createSetPassword] = useState("");
    const [password, createPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const handleClickShowPasswordRepeat = () =>
        setShowPasswordRepeat(!showPasswordRepeat);
    const handleMouseDownPasswordRepeat = () =>
        setShowPasswordRepeat(!showPasswordRepeat);
    /*** Paso 2: Definir variables de estado y creación de objeto a heredar al helper ***/
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [reCaptchaSubmit, setReCaptchaSubmit] = useState();
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "";
    const reCaptchaAction = "set_password";
    const reCaptchaValues = {
        reCaptchaToken,
        setReCaptchaToken,
        reCaptchaSubmit,
        setReCaptchaSubmit,
        reCaptchaKey,
        reCaptchaAction,
    };

    useEffect(() => {
        setReCaptchaSubmit();
        setReCaptchaToken();
    }, [newPasswordObj]);

    if (message === "success") {
        let setPasswordPath = `/sign-up-success`;
        // /reset-password-success-page
        navigate(setPasswordPath);
    }

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required("La contraseña es obligatoria")
            .min(8, "La contraseña debe tener 8 caracteres"),
        confirmPwd: Yup.string()
            .required("La contraseña es obligatoria")
            .oneOf([Yup.ref("password")], "Las contraseñas no coinciden")
            .matches(
                /^(?=.*\d).{8,}$/,
                "Mín. 8 caracteres entre números y letras"
            ),
    });
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, formState } =
        useForm(formOptions);

    const { errors } = formState;

    const onSubmit = (e) => {
        setLoading(true);
        /*** Paso 3: Settear el valor de la variable reCaptchaSubmit una vez que el submit sea exitoso con el objeto correspondiente ***/
        setReCaptchaSubmit({ status: "clicked", e: e });
    };

    /*** Paso 4: Monitorear la variable de estado reCaptchaToken  ***/
    useEffect(() => {
        const { e } = reCaptchaSubmit || {};

        /*** Paso 5: Mientras no sea indefinida y exista e realizar la llamada correspondiente, agregando el valor del recaptcha token  ***/
        if (reCaptchaToken && e) {
            const postData = {
                direct_number: directNumber,
                pin: pinCodeNumber,
                new_password: e?.confirmPwd,
                recaptcha_token: reCaptchaToken,
            };

            props.setPasswordAction(postData);
        }
    }, [reCaptchaToken]);

    useEffect(() => {
        if (response?.status >= 400) {
            setLoading(false);
        }
    }, [setPassword]);

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainer}>
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={7} sx={titleContainer}>
                        <a href="/sign-up">
                            <ArrowCircleLeftIcon sx={iconArrowBack} />
                        </a>
                        <Typography sx={typographyTitleOne}>Establece</Typography>
                        <Typography sx={typographyTitleTwo}>
                            Tu contraseña
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={formContainer}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        {...register("password")}
                                        fullWidth
                                        helperText="Mín. 8 caracteres entre números y letras"
                                        id="outlined-basic"
                                        label="Contraseña"
                                        variant="outlined"
                                        name="password"
                                        value={password}
                                        type={
                                            showPassword
                                                ? "text"
                                                : "password"
                                        }
                                        onChange={(e) => {
                                            createPassword(e.target.value);
                                        }}
                                        className={`form-control ${
                                            errors.password
                                                ? "is-invalid"
                                                : ""
                                        }`}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityIcon />
                                                        ) : (
                                                            <VisibilityOffIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.password?.message}
                                    </div>
                                </Grid>

                                <Grid item xs={12} mt={1}>
                                    <TextField
                                        {...register("confirmPwd")}
                                        fullWidth
                                        id="outlined-basic"
                                        label="Confirmar contraseña"
                                        name="confirmPwd"
                                        value={confirmPwd}
                                        type={
                                            showPasswordRepeat
                                                ? "text"
                                                : "password"
                                        }
                                        onChange={(e) => {
                                            createSetPassword(
                                                e.target.value
                                            );
                                        }}
                                        className={`form-control ${
                                            errors.confirmPwd
                                                ? "is-invalid"
                                                : ""
                                        }`}
                                        InputProps={{
                                            // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPasswordRepeat
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswordRepeat
                                                        }
                                                    >
                                                        {showPasswordRepeat ? (
                                                            <VisibilityIcon />
                                                        ) : (
                                                            <VisibilityOffIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.confirmPwd?.message}
                                    </div>
                                    <PasswordStrengthBar
                                        password={password}
                                        minLength={3}
                                        onChangeScore={(
                                            score,
                                            feedback
                                        ) => {}}
                                    />
                                </Grid>
                                {detail}
                            </Grid>
                            
                            <Box sx={buttonComponent}>
                                <CustomButtonComponent
                                    async={true}
                                    loading={loading}
                                    bgColor={"primary-purple"}
                                    text={"Continuar"}
                                    height={55}
                                    //onClick={() => onSubmit(setLoading(true))}
                                />
                            </Box>
                            <Grid item xs={4} mb={3}>
                                {/* Paso 6: Embeber el componente (helper) */}
                                <FormRecaptchaComponent
                                    reCaptchaValues={reCaptchaValues}
                                />
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({
    setPassword: state.setPassword,
});
const mapDispatchToProps = {
    setPasswordAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetPasswordSignUpPage);
