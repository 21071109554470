import { useContext, useEffect } from "react";

//React Router Dom:
import { useNavigate } from "react-router-dom";

//Redux:
import { connect } from "react-redux";
import { cleanCompleteProcessAction } from "../../../_models/redux/complete-process/clean";

//Context:
import { AppContext } from "_models/context/app";

//Pixels:
import TiktokPixel from "tiktok-pixel";

//Material UI:
import { Grid } from "@mui/material";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const ClientMenuButtonComponent = (props) => {      
    const appContext = useContext(AppContext);
    const { setPressed } = appContext;

    let navigate = useNavigate();

    const signupEvent = () => {
        let ttTrackingOpt = {};
        TiktokPixel.track(
            "ClickButton",
            { description: "Signup Button" },
            ttTrackingOpt
        );
        navigate("/sign-up");
    };

    const loginEvent = () => {
        let ttTrackingOpt = {};
        TiktokPixel.track(
            "ClickButton",
            { description: "Login Button" },
            ttTrackingOpt
        );
        navigate(`/login`);
    };

    useEffect(() => {
        return () => {
            props.cleanCompleteProcessAction();
            window.onpopstate = () => {
                setPressed(false);
            };
        };
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <CustomButtonComponent
                    text="Iniciar sesión"
                    bgColor="primary-purple"
                    height="60px"
                    onClick={loginEvent}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomButtonComponent
                    text="Crear cuenta"
                    bgColor="secondary-purple"
                    height="60px"
                    onClick={signupEvent}
                />
            </Grid>
        </Grid>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    completeProcess: state.completeProcess,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCompleteProcessAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientMenuButtonComponent);
