import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { giftContext } from "./context";

import { useGift } from "./hook"; 

//Components:
import GiftMainPageComponent from "./GiftMainPageComponent";

const giftContainer = (props) => {
    

    return (
        <giftContext.Provider>
            <GiftMainPageComponent />
        </giftContext.Provider>
    );
};

/******/
 
/** Export component connected to redux **/
export default  (giftContainer);
