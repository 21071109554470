import React from "react";

//Material UI:
import { Container, Box } from "@mui/material";

//Images:
import logoImage from "../../../assets/images/logo.png";

//CSS:
import "../../../assets/styles/css/utils/components/logo/logo.css";

function LogoComponent() {
    return (
        <Container maxWidth={"lg"}>
            <Box
                className="container-logo"
                sx={{
                    textAlign: "start",
                    pt: { xs: 4, md: 6 }, 
                    ml: { xs: 0, sm: 3, md: 0 },
                    mb: { xs: 3} ,
                }}
            >
                <a href="/">
                    <img
                        src={logoImage}
                        className={"image-logo-dalefon"}
                        alt="Logo Dalefon"
                    />
                </a>
            </Box>
        </Container>
    );
}

export default LogoComponent;
