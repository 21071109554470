export const boxContainerTitle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mb: { xs: 1, md: 2 },
};

export const typographyTitle = {
    textAlign: "center",
    color: "white",
    fontSize: { xs: 28, md: 35 },
    fontWeight: "700",
    ml: { xs: 0.5, md: 1 },
};

export const gridContainerButton = {
    mt: { xs: 1, md: 2 },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const typographyTermsAndConditions = {
    fontSize: { xs: 11, md: 14 },
    textAlign: "center",
};
