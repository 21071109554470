/** Import: React and other packages */
import React, { useContext, useState, useEffect } from "react";

/** Import: Material UI Components */
import { CssBaseline, Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

/** Importa: Contexts **/
import { HomeActiveOffersContext } from "../context";

//Material UI Styles:
import { containerCircularProgress } from "./styles-mui/genericCard";

//Components:
import NoDataComponent from "./NoDataComponent";
import ContentCardComponent from "./ContentCardComponent";

/** Function Component **/
let GenericCardComponent = (props) => {
    const { type } = props || {};

    /** Custom useContext **/
    const homeActiveOffersContext = useContext(HomeActiveOffersContext);
    const { activeOffers } = homeActiveOffersContext || {};
    const { activeOffersdObj } = activeOffers || {};
    const { active_offers } = activeOffersdObj || {};

    /** Custom useState **/
    const [expandedArray, setExpandedArray] = useState([]);
    const [dataTotals, setDataTotals] = useState(0);
    const [dataUnused, setDataUnused] = useState(0);
    const [dataExpireDate, setDataExpireDate] = useState();
    const [serviceStateArray, setServiceStateArray] = useState([]);
    const [responseTotalsStateArray, setResponseTotalsStateArray] = useState(
        []
    );
    const [graphLabel, setGraphLabel] = useState("");

    const handleExpandClick = (position = undefined) => {
        let newArray = [];

        for (let i = 0; i < expandedArray.length; i++) {
            if (position === i) newArray.push(!expandedArray[i]);
            else newArray.push(expandedArray[i]);
        }

        setExpandedArray(newArray);
    };

    const destroyComponent = (from = "Default") => {};

    useEffect(() => {
        return () => {
            destroyComponent("useEffect [] => return()");
        };
    }, []);

    const dataMethod = () => {};
    const smsMethod = () => {};
    const voiceMethod = () => {};

    let itemsTotals = 0;
    let itemsUnused = 0;

    useEffect(() => {
        let myArray = [];
        active_offers?.map((itm, indx) => myArray.push(false));
        setExpandedArray(myArray);

        itemsTotals = 0;
        itemsUnused = 0;

        if (typeof active_offers !== "undefined") {
            if (Array.isArray(active_offers)) {
                if (active_offers.length > 0) {
                    const responseTotalsArray = [];
                    const responseExpireDate = [];
                    active_offers.map((item, index) => {
                        const serviceArray = [];

                        const {
                            totals,
                            data,
                            sms,
                            voice,
                            carrier_product_name,
                            expireDate,
                        } = item || {
                            totals: {},
                            data: {},
                            sms: {},
                            voice: {},
                        };

                        const responseObj = {};

                        switch (type) {
                            case "data":
                                dataMethod();

                                responseObj.items = data;
                                responseObj.resume = totals?.data;

                                setGraphLabel("MB");

                                break;
                            case "sms":
                                smsMethod();

                                responseObj.items = sms;
                                responseObj.resume = totals?.sms;

                                setGraphLabel("SMS");

                                break;
                            case "voice":
                                voiceMethod();

                                responseObj.items = voice;
                                responseObj.resume = totals?.voice;

                                setGraphLabel("min");

                                break;
                            default:
                                break;
                        }

                        // const { data } = totals || { data: {} };
                        responseObj.resume.name = carrier_product_name;
                        responseObj.resume.expireDate = expireDate;

                        let { total, unused } = responseObj?.resume || {
                            total: 0,
                            unused: 0,
                        };

                        itemsTotals += total;
                        itemsUnused += unused;

                        /*** ***/

                        // const { data } = item || { data: {} };
                        responseObj?.items?.map((itm, indx) =>
                            serviceArray.push(itm)
                        );

                        // setServiceStateArray(...serviceArray);
                        setServiceStateArray((current) => [
                            ...current,
                            serviceArray,
                        ]);

                        responseTotalsArray.push(responseObj?.resume);
                        responseExpireDate.push(responseObj.resume.expireDate);
                    });
                    setDataTotals(itemsTotals);
                    setDataUnused(itemsUnused);
                    setDataExpireDate(responseExpireDate);
                    setResponseTotalsStateArray(responseTotalsArray);
                }
            }
        }
    }, [active_offers]);

    return active_offers?.length >= 0 ? (
        <Container>
            <CssBaseline />
            {active_offers?.length === 0 ? (
                <NoDataComponent />
            ) : (
                <ContentCardComponent
                    dataTotals={dataTotals}
                    dataUnused={dataUnused}
                    graphLabel={graphLabel}
                    dataExpireDate={dataExpireDate}
                    serviceStateArray={serviceStateArray}
                    responseTotalsStateArray={responseTotalsStateArray}
                    expandedArray={expandedArray}
                    handleExpandClick={(index) => handleExpandClick(index)}
                />
            )}
        </Container>
    ) : (
        <Container sx={containerCircularProgress}>
            <CircularProgress color="secondary" />
        </Container>
    );
};

/** Export component **/
export default GenericCardComponent;
