export const gridContainerClientMenu = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const titleContent = {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "start", md: "start" },
    pl: { xs: 2 },
    mt: { xs: 2, md: 0 },
};

export const titleClientMenuOne = {
    fontSize: { xs: 40, md: 55 },
    fontWeight: 400,
    color: "white",
    lineHeight: 1.1,
    mt: { xs: 2, md: 1 },
};

export const titleClientMenuTwo = {
    fontSize: { xs: 46, md: 77 },
    color: "white",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const titleClientMenuThree = {
    fontSize: { xs: 45, md: 65 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
};

export const containerClientMenuForm = {
    textAlign: {
        xs: "center",
        md: "start",
    },
    px: { xs: 2, md: 7 },
    py: { xs: 2, md: 5 },
    display: "flex",
    justifyContent: "center",
    borderRadius: 6,
    background: { xs: "transparent", md: "white" },
    color: { xs: "white", md: "#1e1e1e" },
    boxShadow: { xs: "none", md: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px" },
};

export const typographyDescriptionTitle = {
    mt: { md: 0 },
    fontSize: { md: 25 },
    mb: { md: 3 },
    textAlign: { md: "center" },
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    display: { xs: "none", md: "block" },
};

export const typographyDescription = {
    fontSize: { xs: 18, md: 17 },
    mt: { xs: 2, md: 0 },
    mb: { xs: 5, md: 4 },
    lineHeight: 1.6,
    textAlign: { xs: "start", md: "center" },
};

export const iconArrowBack = {
    fontSize: 32,
    color: "white",
    mb: { xs: 0, md: 1 },
    mt: { xs: 1, md: 0 },
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};
