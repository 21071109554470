export const cardContainer = {
    display: "flex",
    alignItems: "start",
    mt: 2,
};

export const serviceItemContainer = {
    padding: "15px 20px 20px",
    margin: "0 0 25px",
    borderRadius: "10px",
    border: "1px solid #e0e0e0",
    background: "no-repeat 0% 0% #fbf9ff padding-box",
};

export const serviceItemTitle = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "#2d263a",
    margin: "0 !important",
};

export const gridItemContainerPackages = {
    mt: { xs: 2, md: 0 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
