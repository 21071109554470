export const gridContainer = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
};

export const formContainer = {
    mt: { xs: 5, md: 2 },
    backgroundColor: "white",
    borderRadius: 10,
    py: 1,
    px: { xs: 4, md: 6 },
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
};

export const titleContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const typographyTitleOne = {
    fontSize: { xs: 37, md: 55 },
    color: "white",
    lineHeight: 1.5,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 45, md: 69 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: "start",
};

export const iconArrowBack = {
    fontSize: 32,
    color: "white",
    mb: { xs: 1, md: 1 },
    mt: 0,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};

export const typographyDescription = {
    mt: { xs: 2, md: 2.5 },
    fontSize: 16,
    mb: 3,
    textAlign: "center",
    lineHeight: { xs: 1.7, md: 1.6 },
};

export const formControlLabel = {
    ".MuiFormControlLabel-asterisk": {
        display: "none",
    },
};

export const boxCustomRecaptcha = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    my: 2,
};

export const buttonComponent = {
    fontFamily: "Poppins-Regular",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    width: "100%",
    height: "70px",
    background: "white",
    color: "#5f3e9b",
    border: "1px solid",
    borderColor: "#b99de9",
    boxShadow: "none",
    textAlign: "start",
    borderRadius: 3,
    "&:hover": {
        fontWeight: 500,
        border: "2px solid",
        borderColor: "rgb(165 131 213)",
        backgroundColor: "rgb(207, 169, 233, 0.3)",
        boxShadow: "rgb(231 195 255) 0px 0px 15px",
    },
};
