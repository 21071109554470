export const gridItemConsumed = { color: "#52456a" };

export const gridItemValidity = {
    textAlign: { xs: "left", md: "right" },
    color: "#52456a",
    mt: { md: 0 },
};

export const typographyDateLabel = {
    whiteSpace: "nowrap",
    fontSize: 13,
    mt: 0.5,
    p: 0,
};

export const serviceItemFooter = {
    width: "100%",
    fontWeight: 500,
    color: "#2d263a",
    fontSize: 13,
};
